import React from "react";
import Header from "components/header";
import Footer from "components/footer";
import Ad from "components/ad";
import { Helmet } from "react-helmet";

export default function BaseLayout({ children, props }: any) {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <Ad>
        <div
          className="text-center d-flex justify-content-center align-items-center"
          style={{
            background: "var(--bs-pink)",
            height: 128,
            width: "70%",
          }}
        >
          <h2 className="text-white">Espaço para propaganda (Ads)</h2>
        </div>
      </Ad>
      {children}
      <Footer />
    </div>
  );
}
