import React, { PropsWithChildren } from "react";

interface MarkTextProps {
  words: string | string[];
}

export default function MarkText({
  children,
  words,
}: PropsWithChildren<MarkTextProps>) {
  if (typeof children !== "string") {
    return <React.Fragment>{children}</React.Fragment>;
  }

  let search = Array.isArray(words) ? words : words.trim().split(" ");
  search = search.map((w) => w.trim());
  search = search.filter((w) => w.length);

  const regex = new RegExp(`(${search.join("|")})`, "gi");
  const parts = children.split(regex);

  return (
    <React.Fragment>
      {parts.filter(String).map((part, i) => {
        return regex.test(part) ? (
          <mark key={i} className="p-0">
            {part}
          </mark>
        ) : (
          <span key={i}>{part}</span>
        );
      })}
    </React.Fragment>
  );
}
