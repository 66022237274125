import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MarkText from "components/mark-text";
import FlexSearch from "flexsearch";
import { Link, navigate } from "gatsby";
import React, { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  ListGroup,
  Overlay,
  Popover,
} from "react-bootstrap";
import {
  fetchData,
  fetchString,
  formatNumberToText,
  getCustomSearchPageUrl,
  getSearchPageUrl,
} from "src/utils";
import { debounce } from "lodash";
import { Placement } from "react-bootstrap/esm/types";

/* #region Functions */
async function fetchIndex(url: string) {
  let index: string | undefined;

  await fetchString(url)
    .then((s) => {
      index = s;
    })
    .catch((error) => {
      index = undefined;
    });

  return index;
}

async function fetchStore(url: string) {
  let store: object | undefined;

  await fetchData<typeof store>(url)
    .then((data) => {
      store = data;
    })
    .catch((error) => {
      store = undefined;
    });

  return store;
}

async function search(query: string, index: any, store: any) {
  const rawResults = index.search(query);
  return rawResults.map((id: any) => store[id]);
}
/* #endregion */

/* #region Types */
interface SearchBarProps {
  indexUrl: string;
  storeUrl: string;
  viewMoreUrl?: string;
  placeholder?: string;
  maxResultsToShow?: number;
  size?: "sm" | "lg";
  buttonLabel?: string;
  buttonIcon?: IconProp;
  hideIcon?: boolean;
  resultsPlacement?: Placement;
  className?: string;
  onNavigate?: () => void;
  submitToFirstResult?: boolean;
}

interface SearchResults {
  id: string;
  title: string;
  description: string;
  url: string;
}
/* #endregion */

export default function SearchBar({
  indexUrl,
  storeUrl,
  viewMoreUrl,
  placeholder,
  maxResultsToShow = 3,
  size,
  buttonLabel,
  buttonIcon = faSearch,
  hideIcon,
  resultsPlacement = "bottom",
  className,
  onNavigate = () => {},
  submitToFirstResult = true,
}: SearchBarProps) {
  const [query, setQuery] = useState<string>("");
  const [index, setIndex] = useState<any>(null);
  const [store, setStore] = useState<any>(null);
  const [loadData, setLoadData] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadDataFail, setLoadDataFail] = useState(false);
  const [barFocused, setBarFocused] = useState(false);

  const barTarget: any = useRef(null);

  useEffect(() => {
    if (loadData && !loadingData) {
      setLoadingData(true);

      fetchIndex(indexUrl).then((i) => {
        if (i) {
          const importedIndex = FlexSearch.create();
          importedIndex.import(i);

          setIndex(importedIndex);
        } else {
          setLoadDataFail(true);
        }
      });

      fetchStore(storeUrl).then((s) => {
        if (s) {
          setStore(s);
        } else {
          setLoadDataFail(true);
        }
      });
    }

    return () => {
      debouncedChange.cancel();
    };
  }, [loadData]);

  const searchResults: SearchResults[] = useMemo(() => {
    if (!query || !index || !store) return [];

    const rawResults = index.search(query);
    const results = rawResults.map((id: any) => store[id]);

    if (results.length) {
      return results;
    } else {
      return [
        {
          id: "notFound",
          title: "",
          description: "",
          url: "",
        },
      ];
    }
  }, [index, store, query]);

  const viewMoreCount = searchResults.length - maxResultsToShow;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const search = barTarget?.current?.value;

    if (!search) {
      barTarget?.current?.focus();
      return;
    }

    if (submitToFirstResult) {
      if (!searchResults.length) {
        return;
      }

      navigate(searchResults[0].url);
      handleNavigate();
    } else {
      navigate(
        viewMoreUrl
          ? getCustomSearchPageUrl(search, viewMoreUrl)
          : getSearchPageUrl(search)
      );
      handleNavigate();
    }
  };

  const handleChange = (e: FormEvent) => {
    const target: any = e.target;
    const { value } = target;

    setQuery(value);
  };

  const debouncedChange = useMemo(() => {
    return debounce(handleChange, 300);
  }, []);

  const handleFocus = () => {
    setBarFocused(true);
    setLoadData(true);
  };

  const handleBlur = () => {
    setBarFocused(false);
  };

  const handleNavigate = () => {
    if (barTarget?.current?.value) {
      barTarget.current.value = "";
    }

    setQuery("");

    onNavigate();
  };

  return (
    <div>
      <Form
        noValidate
        onSubmit={handleSubmit}
        className={`text-start d-grid gap-3 ${className}`}
      >
        <InputGroup size={size}>
          <FormControl
            disabled={loadDataFail}
            type="text"
            name="search"
            // value={query}
            onChange={debouncedChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={
              loadDataFail ? "Erro inesperado. Tente mais tarde." : placeholder
            }
            ref={barTarget}
            autoComplete="off"
          />

          <Button disabled={loadDataFail} variant="primary" type="submit">
            {!hideIcon && <FontAwesomeIcon icon={buttonIcon} />}
            {buttonLabel && " " + buttonLabel}
          </Button>
        </InputGroup>
      </Form>
      <Overlay
        key="search-box-pop"
        placement={resultsPlacement}
        show={Boolean(barTarget?.current?.value) && barFocused}
        target={barTarget.current}
      >
        <ResultsPopover>
          <Popover.Body className="p-2">
            <ListGroup variant="flush">
              {searchResults.slice(0, maxResultsToShow).map((r) => {
                return r.id === "notFound" ? (
                  <ListGroup.Item variant="light" className="p-2">
                    Nenhum resultado encontrado.
                  </ListGroup.Item>
                ) : (
                  <ListGroup.Item
                    action
                    as={Link}
                    key={r.id}
                    to={r.url}
                    onClick={handleNavigate}
                    variant="light"
                    className="p-2"
                  >
                    <div className="fw-bold text-clamp-line-2">
                      <MarkText words={query}>{r.title}</MarkText>
                    </div>
                    <div className="text-clamp-line-2">
                      <MarkText words={query}>{r.description}</MarkText>
                    </div>
                  </ListGroup.Item>
                );
              })}

              {viewMoreCount > 0 && !submitToFirstResult && (
                <ListGroup.Item
                  action
                  as={Link}
                  key="viewMore"
                  to={
                    viewMoreUrl
                      ? getCustomSearchPageUrl(query, viewMoreUrl)
                      : getSearchPageUrl(query)
                  }
                  onClick={handleNavigate}
                  variant="primary"
                  className="p-2"
                >
                  <b>
                    Veja mais{" "}
                    {`${formatNumberToText(viewMoreCount)} ${
                      viewMoreCount > 1 ? "resultados" : "resultado"
                    }`}
                  </b>
                </ListGroup.Item>
              )}
            </ListGroup>
          </Popover.Body>
        </ResultsPopover>
      </Overlay>
    </div>
  );
}

const ResultsPopover = React.forwardRef(
  ({ popper, children, show: _, ...props }: any, ref: any) => {
    useEffect(() => {
      popper.scheduleUpdate();
    }, [children, popper]);

    return (
      <Popover ref={ref} {...props}>
        {children}
      </Popover>
    );
  }
);
