import cmsSiteSettings from "../content/settings/site.json";

export const siteConfig = {
  siteName: "Vagas Parceiras",
  siteUrl: "https://vagasparceiras.com.br/",
  cmsUrl: "https://admin.vagasparceiras.com.br/",
  language: "pt-BR",

  contentDirectory:
    process.env.NODE_ENV === "production" ||
      process.env.GATSBY_USE_PRODUCTION_CONTENT
      ? "content"
      : "dev-content",

  useProductionContent:
    process.env.NODE_ENV === "production" ||
    process.env.GATSBY_USE_PRODUCTION_CONTENT,

  isProductionBuild: process.env.NODE_ENV === "production",

  disqusConfig: {
    enable:
      process.env.GATSBY_DISQUS_NAME != null && cmsSiteSettings.enableComments,
    shortName: process.env.GATSBY_DISQUS_NAME ?? "",
  },
};

export const seoConfig = {
  title: "{pageTitle} | {siteName}",
  description:
    "Publique sua vaga de emprego, procure uma vaga e envie seu currículo.",
  image: `${siteConfig.siteUrl}/img/seo-og-default.jpg`,
  twitter: "", // @user
};

export const schemaOrgConfig = {
  siteLogo: `${siteConfig.siteUrl}/img/schema-logo-vp.png`,
  sitePublishDate: "2022-07-03T15:00:00.000Z",
  headline: "",
};

export const pwaConfig = {
  name: `${siteConfig.siteName} - ${schemaOrgConfig.headline}`,
  shortName: siteConfig.siteName,
  description: seoConfig.description,
  backgroundColor: "#7e1710",
  themeColor: "#fff",
};

// Don't worry about the trailing slash here, setup it on Gatsby configurations
// You can use any node field name here like {id}, as long as it have a text able value
// Empty string in slugs will disable the page creation
export const createPagesConfig = {
  // Maximum Jobs to include in build/queries
  jobsQueryLimit: 2000,
  jobsQuerySortField: "publishDate",
  jobsQueryOrder: "DESC",

  // Must be multiple of 3 for column alignment
  homePageRecentJobsCount: 12,

  jobListPageSlug: "/vagas/",
  jobsPerPage: 6,

  jobPageSlug: "/vagas/{uid}/{title}/",
  canonicalJobPageSlug: "/vagas/{uid}/",

  contactPageSlug: "/contato/",
  sendResumePageSlug: "/enviar-curriculo/",
  faqPageSlug: "/faq/",
  searchPageSlug: "/pesquisar/",

  // Used to reference jobs in urls like /send-resume/?{paramName}={uid}
  jobIdQueryParamName: "vaga",
  searchTermQueryParamName: "q",
};

export const createPageOverrides = [
  {
    selector: {
      path: "/",
    },

    overrides: {
      context: {
        layout: "full-width",
      },
    },
  },
  {
    selector: {
      path: "/contact/",
    },

    overrides: {
      path: createPagesConfig.contactPageSlug,
    },
  },
  {
    selector: {
      path: "/send-resume/",
    },

    overrides: {
      path: createPagesConfig.sendResumePageSlug,
    },
  },
  {
    selector: {
      path: "/faq/",
    },

    overrides: {
      path: createPagesConfig.faqPageSlug,
    },
  },
  {
    selector: {
      path: "/search/",
    },

    overrides: {
      path: createPagesConfig.searchPageSlug,
    },
  },
];

export default siteConfig;
