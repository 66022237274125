import customWrapPageElement from "./src/global-layout";
import { siteConfig } from "./src/site-config";
import {
  setMomentLocaleGlobally,
  adjustMomentLocaleToUserLocale,
} from "./src/momentjs";
import { config } from "@fortawesome/fontawesome-svg-core";

// Globally import Core Bootstrap CSS
import "styles/global.scss";

// Fix fontawesome css bugs
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

// Global layout
export const wrapPageElement = customWrapPageElement;

// momentjs locale adjustment (runtime)
setMomentLocaleGlobally(siteConfig.language); // Default site language
adjustMomentLocaleToUserLocale(siteConfig.language); // Site language, otherwise user browser first language
