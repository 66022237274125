import _ from "lodash";
import siteConfig, { createPagesConfig } from "./site-config";

export const phoneRegex = /^\(?(\d{2})\)?[\s-]?(\d{4})[\s-]?(\d{4})$/;
export const cellphoneRegex =
  /^\(?(\d{2})\)?[\s-]?(9)[\s-]?(\d{4})[\s-]?(\d{4})$/;

export function getSafeUrlPath(
  sourcePath: string,
  addTrailingSlash = true,
  sanitizeReplacement = "-"
): string {
  let url = sourcePath
    // Removes double spacing
    .replace(/\s+/g, " ")
    .trim()

    // Normalize / diacritic remove
    .normalize("NFKD")
    .replace(/\p{Diacritic}/gu, "")
    .replace(/[\u0300-\u036f]/g, "")

    // Replace spaces
    .replaceAll(/[\s\.\,\;\:\?\!\'\"\@\#\%\&\*\(\)\=\+\\]/g, "-")

    // Sanitize any bad character
    .replace(/[^a-z0-9_\-~\/]/gi, sanitizeReplacement)

    // Remove double traces
    .replace(/\-+/g, "-")
    .toLowerCase();

  if (!url.startsWith("/")) {
    url = "/" + url;
  }

  if (addTrailingSlash && !url.endsWith("/")) {
    url += "/";
  }

  return url;
}

export function getPagingRangeArray(
  currentPage: number,
  minId = 1,
  totalPages = 20,
  RangeLength = 5
) {
  if (RangeLength > totalPages) RangeLength = totalPages;

  let start = currentPage - Math.floor(RangeLength / 2);
  start = Math.max(start, minId);
  start = Math.min(start, minId + totalPages - RangeLength);

  return Array.from({ length: RangeLength }, (el, i) => start + i);
}

export function replaceObjDataInString(
  input: string,
  replace: object,
  urlEncode = false
) {
  let output = input;

  Object.entries(replace).map((value) => {
    if (!Array.isArray(value)) {
      return;
    }

    if (typeof value[0] !== "string" || typeof value[1] !== "string") {
      return;
    }

    output = output.replaceAll(
      `{${value[0]}}`,
      urlEncode ? encodeURI(value[1]) : value[1]
    );
  });

  return output;
}

export function fetchData<Type>(url: string): Promise<Type> {
  return fetch(url).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.json();
  });
}

export function fetchString(url: string): Promise<string> {
  return fetch(url).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.text();
  });
}

export function postData<ResponseType>(
  url: string,
  body: BodyInit | null | undefined,
  headers?: HeadersInit
): Promise<ResponseType> {
  return fetch(url, {
    method: "post",
    body,
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.json();
  });
}

export function postJson<ResponseType>(
  url: string,
  body: string
): Promise<ResponseType> {
  return postData(url, body, {
    "Content-Type": "application/json",
  });
}

export function sendFormJson<ResponseType>(
  url: string,
  values: any,
  convertFilesToBase64 = false,
  removeBlank = true
) {
  return prepareFormJson(values, convertFilesToBase64, removeBlank).then(
    (data) => {
      return postJson<ResponseType>(url, JSON.stringify(data));
    }
  );
}

function prepareFormJson(
  values: any,
  convertFilesToBase64: boolean,
  removeBlank: boolean
) {
  let formData: any = {};
  let filesResolve: Promise<unknown>[] = [];

  for (let key in values) {
    const value = values[key];

    if (value instanceof FileList) {
      // File
      for (var i = 0; i < value.length; i++) {
        if (convertFilesToBase64) {
          filesResolve.push(convertFileToBase64(value[i], key));
        }
      }
    } else {
      // Any other
      formData[key] = removeBlank ? removeEmptyForJsonStringfy(value) : value;
    }
  }

  // Convert all files now
  return Promise.all(filesResolve).then((files) => {
    files.forEach((file: any) => {
      formData[file.key] = _.omit(file, "key");
    });

    return formData;
  });
}

export function removeEmptyForJsonStringfy(input: any) {
  if (typeof input === "string" || Array.isArray(input)) {
    input = input.length ? input : undefined;
  } else if (typeof input === "object") {
    for (let k in input) {
      input[k] = removeEmptyForJsonStringfy(input[k]);
    }
  }

  return input;
}

export function sendFormData<ResponseType>(
  url: string,
  values: any,
  convertFilesToBase64 = false
) {
  return prepareFormData(values, convertFilesToBase64).then((data) => {
    return postData<ResponseType>(url, data);
  });
}

function prepareFormData(values: any, convertFilesToBase64: boolean) {
  const formData = new FormData();
  let filesResolve: Promise<unknown>[] = [];

  for (let key in values) {
    const value = values[key];

    if (typeof value === "string") {
      // String
      formData.append(key, value);
    } else if (value instanceof FileList) {
      // File
      for (var i = 0; i < value.length; i++) {
        if (convertFilesToBase64) {
          filesResolve.push(convertFileToBase64(value[i], key));
        } else {
          formData.append(key, value[i], value[i].name);
        }
      }
    } else {
      // Objects/other
      formData.append(key, JSON.stringify(value));
    }
  }

  // Convert all files now
  return Promise.all(filesResolve).then((files) => {
    files.forEach((file: any) => {
      formData.append(file.key, JSON.stringify(_.omit(file, "key", "data")));
      formData.append(file.key + "-data", file.data);
    });

    return formData;
  });
}

function convertFileToBase64(file: File, key?: string) {
  return new Promise((resolve, reject) => {
    try {
      let fileReader = new FileReader();
      fileReader.onload = function () {
        let data = fileReader.result?.toString();

        if (typeof data === "string") {
          // Remove infos, let only the base64 string stay
          const start = data.indexOf(";") + 8;
          data = data.slice(start, data.length);
        }

        return resolve({
          key,
          name: file.name,
          size: file.size,
          type: file.type,
          data,
        });
      };
      fileReader.readAsDataURL(file);
    } catch (err) {
      reject(err);
    }
  });
}

export function getPageUrl(pageSlug: string) {
  return getSafeUrlPath(pageSlug);
}

export function getSearchPageUrl(searchTerm: string) {
  return `${createPagesConfig.searchPageSlug}?${
    createPagesConfig.searchTermQueryParamName
  }=${encodeURI(searchTerm)}`;
}

export function getCustomSearchPageUrl(
  searchTerm: string,
  searchPageSlug: string
) {
  return `${searchPageSlug}?${
    createPagesConfig.searchTermQueryParamName
  }=${encodeURI(searchTerm)}`;
}

export function isInternalUrl(url: string, siteUrl: string) {
  const site = new URL(siteUrl);
  return new URL(url, site).hostname === site.hostname;
}

// Transform numbers into formatted text for better user experience
// i.e. 12345 => 12.345
export function formatNumberToText(value: number, separator = ".") {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function getSafeExternalUrl(url: string) {
  if (url.startsWith("http://")) {
    return url.replace("http://", "https://");
  } else if (url.startsWith("https://") || url.startsWith("://")) {
    return url;
  } else {
    return "https://" + url;
  }
}

export function getAbsolutePageUrl(pageSlug: string) {
  return getSafeExternalUrl(
    new URL(getSafeUrlPath(pageSlug), siteConfig.siteUrl).href
  );
}

export function getSiteName() {
  return siteConfig.siteName;
}

export function getApiUrl(path: string) {
  return process.env.NODE_ENV === "development"
    ? new URL(path, "http://localhost:8788/").href
    : path;
}
