import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import Ad from "./ad";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

interface SiteFooterProps {
  data: {
    site: {
      siteMetadata: {
        siteName: string;
      };
    };
  };
}

export default function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              siteName
            }
          }
        }
      `}
      render={(data) => <SiteFooter data={data}></SiteFooter>}
    />
  );
}

const socialIcons = [
  {
    icon: faEnvelope,
    url: "mailto:comercial@vagasparceiras.com.br",
  },
  {
    icon: faWhatsapp,
    url: "https://wa.me/5511948310374",
  },
];

const SiteFooter = ({ data }: SiteFooterProps) => {
  const { siteName } = data.site.siteMetadata;

  return (
    <footer className="mt-auto text-center bg-dark">
      <div
        className="container-fluid"
        style={{ background: "var(--bs-gray-800)" }}
      >
        <Ad>
          <div
            className="text-center bg-success d-flex justify-content-center align-items-center my-3"
            style={{ height: 120, width: "70%" }}
          >
            <h2 className="text-center text-white">
              Espaço para propaganda (Ads)
            </h2>
          </div>
        </Ad>
      </div>

      <div className="container text-white py-4 py-lg-4">
        <ul className="list-inline text-black-50">
          {socialIcons.map((s, i) => (
            <li key={i} className="list-inline-item me-4">
              <a href={s.url}>
                <FontAwesomeIcon icon={s.icon} className="fs-3 text-light" />
              </a>
            </li>
          ))}
        </ul>

        <p className="text-muted mb-0">
          © 2022 - {siteName} | Desenvolvido com <small>❤</small>
          {" por "}
          <a
            href="https://helparte.com/"
            target="_blank"
            className="link-light"
          >
            Helparte
          </a>{" "}
          &{" "}
          <a
            href="https://orosete.com.br/"
            target="_blank"
            className="link-light"
          >
            Orosete
          </a>
        </p>
      </div>
    </footer>
  );
};
