// Don't forget to import every needed momentjs locales ("en" not needed)!!
import moment from "moment";
import "moment/locale/pt-br";

export function adjustMomentLocaleToUserLocale(defaultLocale: string): void {
  setMomentLocaleGlobally(getUserLocale(defaultLocale));
}

export function setMomentLocaleGlobally(locale: string) {
  moment.locale(locale.toLowerCase());
}

export function getUserLocale(defaultLocale: string) {
  if (navigator == null) {
    return defaultLocale;
  }

  if (navigator.languages == null) {
    return defaultLocale;
  }

  if (navigator.languages.length == null || navigator.languages.length === 0) {
    return defaultLocale;
  }

  // If user has default language, set it!
  if (
    navigator.languages.filter(
      (locale) => locale.toLowerCase() === defaultLocale.toLowerCase()
    )?.length
  ) {
    return defaultLocale;
  }

  return navigator.languages[0];
}
