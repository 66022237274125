import React, { PropsWithChildren } from "react";
import adSettings from "settings/ads.json";

interface AdProps {}

interface AdSettings {
  enableAll: boolean;
}

export const Ad = ({ children }: PropsWithChildren<AdProps>) => {
  const settings: AdSettings = adSettings;

  if (!settings.enableAll) {
    return null;
  }

  return (
    <div className="container py-3">
      <div className="row justify-content-center">
        <div className="col text-center d-flex justify-content-center align-items-center">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Ad;
