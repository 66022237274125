import { WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from "gatsby";
import React from "react";
import Layout from "src/layouts/base-layout";
import SidebarLayout from "layouts/sidebar-page";

export default function wrapPageElement({
  element,
  props,
}: WrapPageElementBrowserArgs | WrapPageElementNodeArgs) {
  switch (props.pageContext.layout ?? "default") {
    default:
    case "sidebar":
      return (
        <Layout>
          <SidebarLayout>{element}</SidebarLayout>
        </Layout>
      );
    case "canvas":
      return <React.Fragment>{element}</React.Fragment>;
    case "full-width":
    case "fullwidth":
      return <Layout>{element}</Layout>;
  }
}
