import React, { useState } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import SearchBar from "./search-bar";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import menuItems from "settings/site-menu.json";
import { StaticImage } from "gatsby-plugin-image";

interface SiteHeaderProps {
  data: {
    site: {
      siteMetadata: {
        siteName: string;
      };
    };

    localSearchJob: {
      publicIndexURL: string;
      publicStoreURL: string;
    };
  };
}

export default function Header() {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              siteName
            }
          }

          localSearchJob {
            publicIndexURL
            publicStoreURL
          }
        }
      `}
      render={(data) => <SiteHeader data={data}></SiteHeader>}
    />
  );
}

const SiteHeader = ({ data }: SiteHeaderProps) => {
  const { siteName } = data.site.siteMetadata;
  const { publicIndexURL, publicStoreURL } = data.localSearchJob;
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => setMenuOpen(false);

  return (
    <Navbar
      collapseOnSelect
      expand="xl"
      sticky="top"
      bg="light"
      className="py-3 shadow-sm text-light coolMenu"
    >
      <Container>
        <Navbar.Brand as={Link} to="/">
          <StaticImage
            className="d-inline-block align-top"
            src="../images/logo-vp2.png"
            alt={`${siteName} logo`}
            placeholder="dominantColor"
            height={35}
          />
        </Navbar.Brand>

        <Navbar.Toggle onClick={toggleMenu} aria-controls="mainMenu" />
        <Navbar.Offcanvas
          placement="start"
          show={menuOpen}
          onHide={closeMenu}
          scroll={true}
        >
          <Offcanvas.Header closeButton>
            <StaticImage
              className="d-inline-block align-top"
              src="../images/logo-vp2.png"
              alt={`${siteName} logo`}
              placeholder="dominantColor"
              height={35}
            />
          </Offcanvas.Header>

          <Offcanvas.Body className="align-items-center">
            <Nav className="justify-content-end flex-grow-1 pe-3">
              {menuItems.map((item, i) =>
                // Dropdown item
                Array.isArray(item?.items) ? (
                  <NavDropdown
                    key={i}
                    id={`menuDrop${i}`}
                    className="coolMenuItem px-3 ms-2 rounded"
                    title={item.label}
                  >
                    {item.items.map((link, id) => (
                      <NavDropdown.Item
                        active={false}
                        key={id}
                        eventKey={id.toString()}
                        as={Link}
                        to={link.url}
                        onClick={closeMenu}
                      >
                        {link.label}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                ) : (
                  // Common item
                  <Nav.Link
                    key={i}
                    as={Link}
                    to={item.url ?? "#"}
                    onClick={closeMenu}
                    className="coolMenuItem px-3 ms-2 rounded"
                  >
                    {item.label}
                  </Nav.Link>
                )
              )}
            </Nav>

            {/* <Link
              to="/pesquisar/"
              className="d-none d-xl-block ms-2 link-secondary"
            >
              <FontAwesomeIcon icon={faSearch} />
            </Link> */}

            <SearchBar
              indexUrl={publicIndexURL}
              storeUrl={publicStoreURL}
              maxResultsToShow={2}
              placeholder="Pesquisar..."
              className="d-block d-xl-none my-3"
              onNavigate={closeMenu}
            />
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};
