import React, { PropsWithChildren } from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import Ad from "components/ad";
import SearchBar from "components/search-bar";
import menuItems from "settings/site-menu.json";
import { Nav, NavDropdown } from "react-bootstrap";

/* #region Types */
interface SidebarPageProps extends PropsWithChildren {}

interface SiteSidebarPageProps extends SidebarPageProps {
  data: {
    localSearchJob: {
      publicIndexURL: string;
      publicStoreURL: string;
    };
  };
}
/* #endregion */

export default function SidebarPageLayout(props: SidebarPageProps) {
  return (
    <StaticQuery
      query={graphql`
        query {
          localSearchJob {
            publicIndexURL
            publicStoreURL
          }
        }
      `}
      render={(data) => (
        <SiteSidebarPageLayout data={data} {...props}></SiteSidebarPageLayout>
      )}
    />
  );
}

const SiteSidebarPageLayout = ({ data, children }: SiteSidebarPageProps) => {
  const { publicIndexURL, publicStoreURL } = data.localSearchJob;

  return (
    <section>
      {/* Page */}
      <div className="container">
        <div className="row gx-3 justify-content-center">
          {/* Content Body */}
          <div className="col-xl-8 col-xxl-9 py-3">
            <div className="card border-light border rounded border-1 shadow-sm">
              <div className="card-body px-3 px-lg-5 py-3 py-lg-4">
                {children}
              </div>
            </div>
          </div>
          {/* Sidebar */}
          <div className="col-md-8 col-lg-6 col-xl-4 col-xxl-3 py-3">
            <aside>
              <div className="card border-light border rounded border-1 shadow-sm">
                <div className="card-body text-center px-3 py-3 py-lg-4">
                  <SearchBar
                    indexUrl={publicIndexURL}
                    storeUrl={publicStoreURL}
                    placeholder="Digite aqui para pesquisar"
                  />

                  <div className="mt-3 py-3">
                    <h5>Links úteis:</h5>
                    <Nav className="flex-column">
                      {menuItems.map((item, i) =>
                        // Dropdown item
                        Array.isArray(item?.items) ? (
                          <React.Fragment key={i}>
                            {item.items.map((link, id) => (
                              <Nav.Link
                                active={false}
                                key={`k${i}-${id}`}
                                eventKey={`k${i}-${id}`}
                                as={Link}
                                to={link.url}
                              >
                                {link.label}
                              </Nav.Link>
                            ))}
                          </React.Fragment>
                        ) : (
                          // Common item
                          <Nav.Link
                            key={i}
                            as={Link}
                            to={item.url ?? "#"}
                            className="px-3 ms-2 rounded"
                          >
                            {item.label}
                          </Nav.Link>
                        )
                      )}
                    </Nav>
                  </div>

                  <Ad>
                    <div
                      className="text-center bg-success d-flex justify-content-center align-items-center"
                      style={{ height: 300, width: 300 }}
                    >
                      <h4 className="text-center text-white">
                        Espaço para propaganda (Ads)
                      </h4>
                    </div>
                  </Ad>
                  <Ad>
                    <div
                      className="text-center bg-success d-flex justify-content-center align-items-center"
                      style={{ height: 300, width: 300 }}
                    >
                      <h4 className="text-center text-white">
                        Espaço para propaganda (Ads)
                      </h4>
                    </div>
                  </Ad>
                  <Ad>
                    <div
                      className="text-center bg-warning d-flex justify-content-center align-items-center"
                      style={{ height: 600, width: 300 }}
                    >
                      <h4 className="text-center text-white">
                        Espaço para propaganda (Ads)
                      </h4>
                    </div>
                  </Ad>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
};
